var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BackBtn',{attrs:{"go_to":_vm.go_to_org}}),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Редактирование организации ")]},proxy:true}])}),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"7"}},[_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask_on_type),expression:"mask_on_type"}],key:_vm.upd_key,class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_valid_inn(_vm.org_type, v)
                  || _vm.valid_inn_msg(_vm.org_type); }
              ],"label":"ИНН","require":""},model:{value:(_vm.item.inn_organization),callback:function ($$v) {_vm.$set(_vm.item, "inn_organization", $$v)},expression:"item.inn_organization"}}),(_vm.is_require_kpp)?_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.kpp_mask),expression:"kpp_mask"}],key:("kpp_" + _vm.upd_key),class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_correct_kpp(v) || _vm.wrong_kpp; } ],"label":"КПП","require":""},model:{value:(_vm.item.kpp_organization),callback:function ($$v) {_vm.$set(_vm.item, "kpp_organization", $$v)},expression:"item.kpp_organization"}}):_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.kpp_mask),expression:"kpp_mask"}],key:("kpp_" + _vm.upd_key),class:_vm.$style.item,attrs:{"rules":[
                function (v) { return v ? _vm.is_correct_kpp(v) || _vm.wrong_kpp : true; } ],"label":"КПП"},model:{value:(_vm.item.kpp_organization),callback:function ($$v) {_vm.$set(_vm.item, "kpp_organization", $$v)},expression:"item.kpp_organization"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('v-radio-group',{class:_vm.$style.item,attrs:{"value":_vm.org_type,"row":""},on:{"change":_vm.upd_org_type}},_vm._l((_vm.orgz_radios),function(radio,i){return _c('Radio',{key:i,attrs:{"label":radio.label,"value":radio.value}})}),1),(_vm.is_ind_entrepreneur)?_c('FileInput',{class:_vm.$style.item,attrs:{"value":_vm.item_file('ip_scan_file'),"src":_vm.item.ip_scan_file,"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"Свидетельство о регистрации ИП","require":""},on:{"change":function($event){_vm.item.ip_scan_file = $event}}}):_vm._e()],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Наименование организации","require":""},model:{value:(_vm.item.name_organization),callback:function ($$v) {_vm.$set(_vm.item, "name_organization", $$v)},expression:"item.name_organization"}}),_c('div',{class:_vm.$style.item},[_c('Datepicker',{attrs:{"date_prop":_vm.item.date_registration_company,"rules":[
                  function (v) { return _vm.is_require(v) || _vm.require_message; }
                ],"label":"Дата регистрации","require":"","max":_vm.now_date},on:{"date_changed":function($event){_vm.item.date_registration_company = $event}}})],1)],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Город","require":""},model:{value:(_vm.item.city),callback:function ($$v) {_vm.$set(_vm.item, "city", $$v)},expression:"item.city"}}),_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Регион","require":""},model:{value:(_vm.item.region),callback:function ($$v) {_vm.$set(_vm.item, "region", $$v)},expression:"item.region"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Федеральный округ","require":""},model:{value:(_vm.item.federal_district),callback:function ($$v) {_vm.$set(_vm.item, "federal_district", $$v)},expression:"item.federal_district"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"Ответственный менеджер ДПП","require":""},model:{value:(_vm.item.manager_dpp),callback:function ($$v) {_vm.$set(_vm.item, "manager_dpp", $$v)},expression:"item.manager_dpp"}}),_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"Ответственный менеджер КС","require":""},model:{value:(_vm.item.manager_ks),callback:function ($$v) {_vm.$set(_vm.item, "manager_ks", $$v)},expression:"item.manager_ks"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Генеральный директор","require":""},model:{value:(_vm.item.general_manager),callback:function ($$v) {_vm.$set(_vm.item, "general_manager", $$v)},expression:"item.general_manager"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"ФИО подписанта","require":""},model:{value:(_vm.item.name_signatory),callback:function ($$v) {_vm.$set(_vm.item, "name_signatory", $$v)},expression:"item.name_signatory"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('Select',{class:_vm.$style.item,attrs:{"items":_vm.basic_docs,"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Действует на основании","blue_icon":"","require":""},model:{value:(_vm.item.basis_document),callback:function ($$v) {_vm.$set(_vm.item, "basis_document", $$v)},expression:"item.basis_document"}}),_c('FileInput',{class:_vm.$style.item,attrs:{"value":_vm.item_file('attorney_file'),"src":_vm.item.attorney_file,"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"Скан доверенности","accept":".pdf, .jpg, .jpeg,","require":""},on:{"change":function($event){_vm.item.attorney_file = $event}}})],1)]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"7"}},[_c('h2',{class:_vm.$style.itemsTitle},[_vm._v(" Контакты ")]),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; } ],"label":"ФИО контактного лица","require":""},model:{value:(_vm.item.contact_person),callback:function ($$v) {_vm.$set(_vm.item, "contact_person", $$v)},expression:"item.contact_person"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Должность контактного лица","require":""},model:{value:(_vm.item.position_of_the_contact_person),callback:function ($$v) {_vm.$set(_vm.item, "position_of_the_contact_person", $$v)},expression:"item.position_of_the_contact_person"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_email(v) || _vm.invalid_email; }
              ],"label":"Е-mail (для обмена электронными документами)","require":""},model:{value:(_vm.item.email_1),callback:function ($$v) {_vm.$set(_vm.item, "email_1", $$v)},expression:"item.email_1"}}),_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_email(v) || _vm.invalid_email; }
              ],"label":"Е-mail (для получения лицензий программных продуктов)","require":""},model:{value:(_vm.item.email_2),callback:function ($$v) {_vm.$set(_vm.item, "email_2", $$v)},expression:"item.email_2"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_email(v) || _vm.invalid_email; }
              ],"label":"Е-mail (для получения СУБлицензий программных продуктов)","require":""},model:{value:(_vm.item.email_3),callback:function ($$v) {_vm.$set(_vm.item, "email_3", $$v)},expression:"item.email_3"}}),_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_email(v) || _vm.invalid_email; }
              ],"label":"Е-mail (для получения рассылок)","require":""},model:{value:(_vm.item.email_4),callback:function ($$v) {_vm.$set(_vm.item, "email_4", $$v)},expression:"item.email_4"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],key:("telephone_" + _vm.upd_key),class:_vm.$style.item,attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; },
                function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
              ],"label":"Телефон","require":""},model:{value:(_vm.item.telephone),callback:function ($$v) {_vm.$set(_vm.item, "telephone", $$v)},expression:"item.telephone"}}),_c('TextField',{class:_vm.$style.item,attrs:{"label":"Сайт"},model:{value:(_vm.item.website),callback:function ($$v) {_vm.$set(_vm.item, "website", $$v)},expression:"item.website"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Адрес (юридический)","require":""},model:{value:(_vm.item.legal_address),callback:function ($$v) {_vm.$set(_vm.item, "legal_address", $$v)},expression:"item.legal_address"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
                function (v) { return _vm.is_require(v) || _vm.require_message; }
              ],"label":"Адрес (фактический)","require":""},model:{value:(_vm.item.actual_address),callback:function ($$v) {_vm.$set(_vm.item, "actual_address", $$v)},expression:"item.actual_address"}})],1)])],1),_c('v-divider',{staticClass:"mb-4"}),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.form_valid}},[_vm._v(" Отправить запрос на изменение ")]),_c('v-btn',{attrs:{"depressed":""},on:{"click":_vm.cancel_clicked}},[_vm._v(" Отменить ")])],1)],1),_c('SuccessDialog',{attrs:{"center_title":"","center_text":""},on:{"close":_vm.go_back},scopedSlots:_vm._u([(_vm.is_success_dialog)?{key:"title",fn:function(){return [_vm._v(" Запрос отправлен ")]},proxy:true}:null,{key:"text",fn:function(){return [_vm._v(" "+_vm._s(_vm.dialog_msg)+" ")]},proxy:true}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }